import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  t
} from 'i18next';

import {
  formatDateRange
} from 'src/shared/utils';

dayjs.extend(isBetween);

export const formatDateValue = (
  inputValue: (Date | null)[] | null,
  lang: string,
) => {
  if (!inputValue) {
    return t('filters.customDateRange');
  }

  const [start, end] = inputValue;

  if (!start) {
    return t('filters.customDateRange');
  }

  return formatDateRange({
    start,
    end,
    language: lang,
  });
};

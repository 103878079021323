import React, {
  ChangeEvent, FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  MAX_MB_FILE_SIZE,
  SVG_TYPE,
  getMaxAllowedFileSize,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  usePostOrgLogoFileMutation
} from 'src/shared/api/organizations/organizationsApi';
import {
  errorToast, successfulToast, Avatar
} from 'src/shared/components';

import * as Style from './OrganizationLogoUpload.style';

interface OrganizationLogoUploadProps {
  logoSrc: string | null;
  name: string;
  id: string;
}

export const OrganizationLogoUpload: FC<OrganizationLogoUploadProps> = ({
  logoSrc,
  name,
  id,
}) => {
  const {
    t
  } = useTranslation();

  const [orgLogo, setOrgLogo] = useState<string | null>(logoSrc);

  const [newFileSrc, setNewFileSrc] = useState<File | null>(null);

  const validateFile = (file: File) => {
    const isNotValidTypeFile = !/^image\/(png|jpeg|jpg|webp)$/i.test(file.type)
      && file.type !== SVG_TYPE;

    if (isNotValidTypeFile) {
      errorToast(t('errors.selectValidImgFile'));

      return;
    }

    const isBigFile = file.size > getMaxAllowedFileSize(MAX_MB_FILE_SIZE);

    if (isBigFile) {
      errorToast(`${t('errors.selectImgFileLessThen')} ${MAX_MB_FILE_SIZE}Mb`);

      return;
    }

    setNewFileSrc(file);
  };

  const [loadOrgLogo, {
    isLoading: isOrgLogoLoading
  }] = usePostOrgLogoFileMutation();

  const loadFile = async (idOrg: string, formFileData: FormData) => {
    try {
      const {
        message, data
      } = await loadOrgLogo({
        id: idOrg,
        formData: formFileData,
      }).unwrap();

      successfulToast(message);

      setOrgLogo(data.organization.logoUrl);
      setNewFileSrc(null);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  useEffect(
    () => {
      if (newFileSrc) {
        const formData = new FormData();

        formData.append(
          'logo',
          newFileSrc,
          newFileSrc.name
        );

        loadFile(
          id,
          formData
        );
      }
    },
    [newFileSrc]
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files?.length || isOrgLogoLoading) {
      return;
    }

    const selectedFile = event.target.files[0];

    validateFile(selectedFile);
  };

  const splittedName = name.split(' ').slice(
    0,
    2
  ).join(' ');

  return (
    <Style.AvatarRow>
      <Avatar
        src={orgLogo}
        userName={splittedName}
        size={72}
        isLogo
      />

      <Style.AvatarSettings>
        <Style.ActionLabel htmlFor="fileInput">
          <input
            type="file"
            id="fileInput"
            accept=".jpg, .jpeg, .png, .svg, .webp"
            onChange={handleFileChange}
            className="hidden"
          />

          {t('organisation.uploadLogo')}
        </Style.ActionLabel>

        <Style.AvatarDescription>
          {t('organisation.logoDescription')}
        </Style.AvatarDescription>
      </Style.AvatarSettings>
    </Style.AvatarRow>
  );
};

import React, {
  FC, FormEvent
} from 'react';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  Link, useNavigate, useLocation
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  AcceptTermsBlock,
  Button,
  Input,
  InputType,
  successfulToast,
} from 'src/shared/components';
import {
  ArrowRightIcon, CheckmarkCircleIcon
} from 'src/shared/icons';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  useTypedSelector, useTypedDispatch
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  usePostSignUpMutation
} from 'src/redux/openapi';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  setEmailToVerify
} from 'src/redux/verification';

import {
  InputNames
} from '../types';

import {
  SubmitButton
} from './SubmitButton';
import * as Style from './Login.styles';
import {
  GroupInviteLabel
} from './GroupInviteLabel';

interface CreateAccountSocialPageProps {
  isSocial?: boolean;
}

interface CreateAccountForm {
  [InputNames.EMAIL]: string;
  [InputNames.USERNAME]: string;
  [InputNames.FULL_NAME]: string;
  [InputNames.PASSWORD]: string;
  [InputNames.AGREEMENT]: boolean;
}

const formId = 'CreateAccountForm';

export const CreateAccountPage: FC<CreateAccountSocialPageProps> = ({
  isSocial,
}) => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    email: userEmail, name
  } = useTypedSelector(userSelectors.user);

  const dispatch = useTypedDispatch();

  const {
    createAccountSchema
  } = useGetInputValidation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: {
      errors
    },
  } = useForm<CreateAccountForm>({
    mode: 'onTouched',
    defaultValues: {
      [InputNames.EMAIL]: userEmail || '',
      [InputNames.USERNAME]: name || '',
      [InputNames.FULL_NAME]: '',
      [InputNames.PASSWORD]: '',
      [InputNames.AGREEMENT]: false,
    },
    resolver: zodResolver(createAccountSchema),
  });

  const [signup, {
    isLoading
  }] = usePostSignUpMutation();

  const onSubmit = async ({
    email,
    password,
    username,
    agreement,
    fullName,
  }: {
    email: string;
    password: string;
    username: string;
    agreement?: boolean;
    fullName: string;
  }) => {
    const trimmedUsername = username.trim();

    if (!trimmedUsername) {
      setValue(
        InputNames.USERNAME,
        ''
      );

      return;
    }

    const trimmedEmail = email.trim();

    if (!trimmedEmail || !password || !agreement) {
      return;
    }

    try {
      const response = await signup({
        signUpBodySchema: {
          email: trimmedEmail,
          password,
          username: trimmedUsername,
          agreement,
          fullName: fullName.trim(),
        },
      }).unwrap();

      const {
        group, verificationRequired
      } = response.data;

      successfulToast(response.message);

      if (verificationRequired) {
        dispatch(setEmailToVerify(verificationRequired.email));

        navigate(
          `/${ROUTE.EMAIL_VERIFICATION}`,
          {
            state: {
              from: location.pathname,
            },
          }
        );

        return;
      }

      if (group) {
        navigate(`${ROUTE.ROOT}`);
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    handleSubmit(onSubmit);
  };

  const navigateToLogin = () => {
    navigate(`/${isSocial ? ROUTE.SOCIAL_LOGIN : ROUTE.LOGIN}`);
  };

  return (
    <Style.MainContainer>
      <Style.Title>
        {isSocial ? t('login.createAccountToJoin') : t('login.createAccount')}
      </Style.Title>

      {isSocial && <GroupInviteLabel />}

      <Style.Form
        autoComplete="off"
        id={formId}
        onSubmit={onFormSubmit}
      >
        <Controller
          name={InputNames.EMAIL}
          control={control}
          render={({
            field
          }) => {
            return (
              <Input
                label={t('common.email')}
                type={InputType.EMAIL}
                isRequired
                value={field.value}
                onChange={(e) => field.onChange(e)}
                errorMessage={errors[InputNames.EMAIL]?.message}
                bottomMessage={t('login.emailBottomMsg')}
                onBlur={field.onBlur}
              />
            );
          }}
        />

        <Controller
          name={InputNames.USERNAME}
          control={control}
          render={({
            field
          }) => {
            return (
              <Input
                label={t('common.username')}
                isRequired
                value={field.value}
                onChange={(e) => field.onChange(e)}
                errorMessage={errors[InputNames.USERNAME]?.message}
                bottomMessage={t('login.usernameBottomMsg')}
                onBlur={field.onBlur}
              />
            );
          }}
        />

        <Controller
          name={InputNames.FULL_NAME}
          control={control}
          render={({
            field
          }) => {
            return (
              <Input
                label={t('common.fullName')}
                isRequired
                value={field.value}
                onChange={(e) => field.onChange(e)}
                errorMessage={errors[InputNames.FULL_NAME]?.message}
                bottomMessage={(
                  <>
                    {t('login.informationIsRequiredBy')}

                    <Link
                      to={`/${ROUTE.TERMS_REGULATIONS}`}
                      className="text-button-blue"
                      target="_blank"
                    >
                      {` ${t('common.TC')} `}
                    </Link>

                    {t('login.andWillNeverBeVisibleToOtherUsers')}
                  </>
                )}
                onBlur={field.onBlur}
                placeholder={t('common.fullNamePlaceholder')}
              />
            );
          }}
        />

        <Controller
          name={InputNames.PASSWORD}
          control={control}
          render={({
            field
          }) => {
            return (
              <Input
                label={t('common.password')}
                isRequired
                value={field.value}
                type={InputType.PASSWORD}
                onChange={(e) => field.onChange(e)}
                errorMessage={errors[InputNames.PASSWORD]?.message}
                onBlur={field.onBlur}
                placeholder={t('login.passwordPlaceholder')}
              />
            );
          }}
        />
      </Style.Form>

      <Controller
        name={InputNames.AGREEMENT}
        control={control}
        render={({
          field
        }) => {
          return (
            <AcceptTermsBlock
              termsAccepted={field.value || false}
              handleTermsAccept={() => field.onChange(!field.value)}
              errorMessage={errors[InputNames.AGREEMENT]?.message}
            />
          );
        }}
      />

      <Style.ButtonsContainer>
        <SubmitButton
          title={isSocial ? t('buttons.signUp') : t('buttons.finish')}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          customRightIcon={
            isSocial ? (
              <ArrowRightIcon className="text-white" />
            ) : (
              <CheckmarkCircleIcon className="text-white" />
            )
          }
          formId={formId}
        />

        <Button
          variant="big-black"
          className="font-bold text-17-26 md:text-xl text-white"
          onClick={navigateToLogin}
        >
          {t('buttons.returnToLogin')}
        </Button>
      </Style.ButtonsContainer>
    </Style.MainContainer>
  );
};

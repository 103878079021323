import dayjs from 'dayjs';
import 'dayjs/locale/de';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ms from 'ms';
import i18next, {
  t
} from 'i18next';

import {
  TimeSpansValues
} from 'src/redux/timeSpans';
import {
  FilterDateType,
  SortBy,
} from 'src/widgets/CommentsFilterContext/types';
import {
  LocalizationService
} from 'src/shared/services';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const TWO_WEEKS = 14 * DAY;
export const MONTH = 30 * DAY;
export const YEAR = 12 * MONTH;

type TimeUnit =
  | 'sec'
  | 'years'
  | 'year'
  | 'months'
  | 'month'
  | 'days'
  | 'day'
  | 'hours'
  | 'hour'
  | 'mins'
  | 'min'
  | 'minute'
  | 'minutes';

export const dateFormats = {
  short: 'MMM D',
  dayOnly: 'D',
};

export const getAxisDateString = ({
  fromDate,
  interval,
}: {
  fromDate: number;
  interval?: number;
}) => {
  const currentLanguage = LocalizationService.getLanguage();

  const actualDate = dayjs(fromDate)
    .locale(currentLanguage)
    .format(dateFormats.short);

  if (!interval) {
    return actualDate;
  }

  const toDate = new Date(fromDate + interval);

  const isMonthSame = toDate.getMonth() === new Date(fromDate).getMonth();

  const formattedToDate = dayjs(toDate)
    .locale(currentLanguage)
    .format(isMonthSame ? dateFormats.dayOnly : dateFormats.short);

  return `${actualDate} - ${formattedToDate}`;
};

export const formatDateRange = ({
  start,
  end,
  language,
}: {
  start: Date | number | null | string;
  end?: Date | number | null | string;
  language: string;
}) => {
  let formattedStart = dayjs(start).locale(language).format('MMM D, YYYY');

  if (end) {
    const startYear = dayjs(start).locale(language).format('YYYY');
    const endYear = dayjs(end).locale(language).format('YYYY');

    if (startYear === endYear) {
      formattedStart = dayjs(start).locale(language).format('MMM D');
    }

    const formattedEnd = dayjs(end).locale(language).format('MMM D, YYYY');

    return `${formattedStart} - ${formattedEnd}`;
  }

  return formattedStart;
};

export const formatDateStringToDate = (
  date: Date | number | string,
  format = 'MMM D, YYYY',
) => dayjs(
  date,
  format,
  i18next.language
).toDate();

export const formatDateWithTime = (date: Date) => {
  const currentLanguage = LocalizationService.getLanguage();

  return dayjs(date).locale(currentLanguage).format('HH:mm MMM D, YYYY');
};

export const getRoundedDifferenceToNow = (endDate: Date | string) => {
  const diffInMinutes = Math.abs(dayjs().diff(
    endDate,
    'minute'
  ));

  let unit: 'minute' | 'day' | 'hour' = 'minute';

  if (diffInMinutes >= 1440) {
    unit = 'day';
  } else if (diffInMinutes >= 60) {
    unit = 'hour';
  }

  const diff = Math.round(Math.abs(dayjs().diff(
    endDate,
    unit
  )));

  const formattedUnit: TimeUnit = `${unit}${diff !== 1 ? 's' : ''}`;

  return `${diff} ${t(`date.${formattedUnit}`)}`;
};

export const getFilteredTimeRange = (period: TimeSpansValues) => {
  switch (period) {
    case TimeSpansValues.last7Days: {
      return ms(WEEK);
    }
    case TimeSpansValues.last30Days: {
      return ms(MONTH);
    }
    case TimeSpansValues.twoWeeks: {
      return ms(TWO_WEEKS);
    }
    default: {
      return ms(DAY);
    }
  }
};

export const getUserTimeZone = () => {
  return dayjs.tz.guess();
};

export const getFilteredTimeRangeComments = (period: FilterDateType) => {
  const userTimeZone = getUserTimeZone();
  const now = dayjs().tz(userTimeZone);

  if (period === FilterDateType.ALL_TIME || period === FilterDateType.CUSTOM) {
    return undefined;
  }

  switch (period) {
    case FilterDateType.IN_48_HOURS:
      return now.subtract(
        2,
        'days'
      ).toDate().toJSON();
    case FilterDateType.WEEK:
      return now.subtract(
        7,
        'days'
      ).toDate().toJSON();
    case FilterDateType.IN_24_HOURS:
    default:
      return now.subtract(
        1,
        'day'
      ).toDate().toJSON();
  }
};

export const getCurrentSortByValue = (
  sortBy: SortBy,
): 'commentsCount' | 'commendationsCount' | 'createdAt' => {
  switch (sortBy) {
    case SortBy.MOST_DISCUSSION:
      return 'commentsCount';

    case SortBy.MOST_LIKED:
      return 'commendationsCount';

    case SortBy.CHRONOLOGICAL:
    default:
      return 'createdAt';
  }
};

export const getTimeAgo = (num: number | string) => {
  const now = dayjs();
  const userTimeZone = dayjs.tz.guess();
  const createdAtUserZone = dayjs.utc(num).tz(userTimeZone);
  const formattedDate = createdAtUserZone.format();
  const sendingDate = dayjs(Date.parse(formattedDate));

  const seconds = now.diff(
    sendingDate,
    'seconds'
  );

  const minutes = now.diff(
    sendingDate,
    'minutes'
  );

  const hours = now.diff(
    sendingDate,
    'hours'
  );

  const days = now.diff(
    sendingDate,
    'days'
  );

  const months = now.diff(
    sendingDate,
    'months'
  );

  const years = now.diff(
    sendingDate,
    'years'
  );

  let translationKey: TimeUnit = 'sec';
  let timePeriod = seconds;

  if (years > 0) {
    translationKey = years > 1 ? 'years' : 'year';
    timePeriod = years;
  } else if (months > 0) {
    translationKey = months > 1 ? 'months' : 'month';
    timePeriod = months;
  } else if (days > 0) {
    translationKey = days > 1 ? 'days' : 'day';
    timePeriod = days;
  } else if (hours > 0) {
    translationKey = hours > 1 ? 'hours' : 'hour';
    timePeriod = hours;
  } else if (minutes > 0) {
    translationKey = minutes > 1 ? 'mins' : 'min';
    timePeriod = minutes;
  }

  return `${timePeriod} ${t(`date.${translationKey}`)}`;
};

export const getTime = (date: string | number | Date) => {
  const userTimeZone = getUserTimeZone();

  return dayjs.utc(date).tz(userTimeZone).format('HH:mm');
};

export const getWeekInMsValue = (period: number) => ms(`${period} weeks`);

export const getWeekFromMsValue = (period: number) => period / WEEK;

export const isTodaySameOrBeforeDate = (date: string | number) => {
  return dayjs().isSameOrBefore(dayjs(date));
};

export const isTodayBeforeDate = (date: string | number) => {
  return dayjs().isBefore(dayjs(date));
};

export const getTimeRangeToDisplay = (
  range?: keyof typeof TimeSpansValues | null,
) => {
  if (!range) {
    return null;
  }

  switch (range) {
    case TimeSpansValues.upToday:
      return `24${t('date.h')}`;

    case TimeSpansValues.last7Days:
      return `7${t('date.d')}`;

    case TimeSpansValues.twoWeeks:
      return `14${t('date.d')}`;

    case TimeSpansValues.last30Days:
      return `1${t('date.month')[0]}`;

    default:
      return `24${t('date.h')}`;
  }
};

export const getDaysUntilNDays = (
  date: string | number | Date,
  days: number,
) => {
  const today = dayjs();

  const nDaysBeforeToday = today.subtract(
    days,
    'day'
  );

  const inputDate = dayjs(date);

  const isBeforeDate = inputDate.isSameOrBefore(
    nDaysBeforeToday,
    'day'
  );

  if (isBeforeDate) {
    return null;
  }

  const daysLeft = days - today.diff(
    inputDate,
    'day'
  );

  const label = daysLeft > 1 ? t('date.days') : t('date.day');

  return `${daysLeft} ${label}`;
};

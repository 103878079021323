import React, {
  FC, useEffect
} from 'react';
import {
  useNavigate
} from 'react-router-dom';

import {
  useGetGroupsUsersInviteAcceptSessionQuery,
  usePostGroupsInviteAcceptSessionMutation,
} from 'src/redux/openapi';
import {
  isErrorWithMessage, ROUTE
} from 'src/shared/utils';
import {
  useGetCurrentUserMutation
} from 'src/shared/api/users/usersApi';
import {
  LoginSocialsInvite,
  successfulToast,
  warningToast,
} from 'src/shared/components';

export const GroupInviteLabel: FC = () => {
  const navigate = useNavigate();

  const {
    data: invitationData,
    isError,
    error,
  } = useGetGroupsUsersInviteAcceptSessionQuery();

  const [getCurrentUser] = useGetCurrentUserMutation();
  const [acceptUserInvitation] = usePostGroupsInviteAcceptSessionMutation();

  const acceptCurrentUserInvitation = async () => {
    try {
      const user = await getCurrentUser().unwrap();

      if (!user) {
        throw new Error();
      }

      const result = await acceptUserInvitation().unwrap();

      successfulToast(result.message);
    } catch {
      if (isErrorWithMessage(error)) {
        warningToast(error.data.message);
      }

      navigate(
        `/${ROUTE.LOGIN}`,
        {
          replace: true,
        }
      );
    }
  };

  useEffect(
    () => {
      if (isError) {
        acceptCurrentUserInvitation();
      }
    },
    [isError]
  );

  if (!invitationData?.data) {
    return null;
  }

  const {
    group: {
      name: groupName = null
    } = {}, userEmail: email
  } = invitationData.data;

  return (
    <LoginSocialsInvite
      email={email}
      groupName={groupName || ''}
    />
  );
};

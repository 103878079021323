import React, {
  FC, useState
} from 'react';
import {
  Link, useLocation, useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  EditPopoverMenu,
  MenuItem,
  Table,
  TableContentWrapper,
  TableHeader,
  TableSearch,
  TableTabs,
} from 'src/shared/components';
import {
  groupId, organisationTab
} from 'src/shared/utils';
import {
  useDebouncedValue
} from 'src/shared/hooks';
import {
  UserGroupRole, Organization
} from 'src/redux/openapi';
import {
  CommunitySection,
  QuestionsFilter,
  CommunityActions,
} from 'src/widgets';
import {
  ScoringFilters
} from 'src/widgets/QuestionScoring';

import {
  OrganisationTabs, getEditMenuMap, getTabsMap
} from './utils';
import {
  CommunityContent,
  GroupsTabContent,
  QuestionScoringContent,
  SetsQuestionsContent,
} from './ui';

interface OrganisationTableProps {
  organization: Organization;
}

export const OrganisationTable: FC<OrganisationTableProps> = ({
  organization,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');

  const [communityAction, setCommunityAction] = useState<CommunityActions | null>(null);

  const location = useLocation();

  const {
    id, role
  } = organization;

  const currentTab = searchParams.get(organisationTab) || OrganisationTabs.SetsAndQuestions;

  const activeGroupId = searchParams.get(groupId) || null;

  const onTabChange = (tabId: string) => {
    searchParams.set(
      organisationTab,
      tabId
    );

    setSearchParams(searchParams);
    setSearchQuery('');
  };

  const debouncedQuery = useDebouncedValue(
    searchQuery,
    500
  );

  const tabs = getTabsMap();

  const {
    t
  } = useTranslation();

  const isAdmin = role !== UserGroupRole.USER;

  return (
    <TableContentWrapper>
      <TableHeader>
        <TableTabs
          tabs={tabs}
          currentTab={currentTab}
          onTabChange={onTabChange}
        />

        <div className="flex gap-3 items-center justify-end">
          <TableSearch
            searchQuery={searchQuery}
            placeholder={t('common.startTyping')}
            handleSearch={setSearchQuery}
          />

          {currentTab === OrganisationTabs.SetsAndQuestions && (
            <>
              <QuestionsFilter withBorder={false} />

              {isAdmin && (
                <EditPopoverMenu
                  placement="bottom-end"
                  buttonProps={{
                    withBorder: false,
                  }}
                  tooltipMessage={t('modal.editQuestions')}
                  title={t('modal.editQuestions')}
                >
                  {getEditMenuMap(
                    location.pathname,
                    activeGroupId
                  ).map(
                    (item) => (
                      <MenuItem
                        key={item.label}
                        $withHover
                        as={Link}
                        to={item.link}
                        state={item.state}
                      >
                        {item.label}
                      </MenuItem>
                    ),
                  )}
                </EditPopoverMenu>
              )}
            </>
          )}

          {currentTab === OrganisationTabs.QuestionScoring && (
            <ScoringFilters />
          )}

          {currentTab === OrganisationTabs.Community && isAdmin && (
            <CommunitySection.CommunityMoreBtn
              setActionType={setCommunityAction}
              organizationId={id}
            />
          )}
        </div>
      </TableHeader>

      <Table>
        {currentTab === OrganisationTabs.Groups && (
          <GroupsTabContent
            organizationId={id}
            searchQuery={debouncedQuery}
          />
        )}

        {currentTab === OrganisationTabs.SetsAndQuestions && (
          <SetsQuestionsContent
            organizationId={id}
            role={role}
            searchQuery={debouncedQuery}
          />
        )}

        {currentTab === OrganisationTabs.Community && (
          <CommunityContent
            organization={organization}
            searchQuery={debouncedQuery}
            setSearchQuery={setSearchQuery}
          />
        )}

        {currentTab === OrganisationTabs.QuestionScoring && (
          <QuestionScoringContent
            organizationId={id}
            searchQuery={debouncedQuery}
          />
        )}
      </Table>

      <CommunitySection.ModalSendMsgToMembers
        isOpen={communityAction === CommunityActions.SendMessages}
        closeModal={() => setCommunityAction(null)}
      />
    </TableContentWrapper>
  );
};

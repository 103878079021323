import React, {
  FC, useEffect
} from 'react';
import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  useNavigate,
} from 'react-router-dom';

import {
  ComingSoon,
  NotFound,
  Login,
  Question,
  OnboardingQuestion,
  GroupPage,
  Organisation,
  CreateGroup,
  GroupsOverview,
  NotificationsPage,
  Community,
  GroupPromotionStatus,
  PrivacyPolicy,
  TermOfServices,
  FaqPage,
  OrganizationManagement,
  SetupOrganization,
  ManageInvitations,
  CalibrationTool,
  CountDown,
  EditQuestions,
  EditOrgQuestionsInBulk,
  CalibrationQuestion,
  CalibrationResults,
  PostEstimationFeedback,
  ProfilePage,
  EditQuestionsInGroup,
  AddAdminQuestionSet,
  AddOgrQuestionSet,
} from 'src/pages';
import {
  AccountBannedPage,
  CreateAccountPage,
  CreatePasswordPage,
  CreationSuccessPage,
  EmailVerification,
  EnterPasswordPage,
  RecoveryCodePage,
  ResetPasswordPage,
} from 'src/pages/Login/components';
import {
  useTypedDispatch
} from 'src/redux';
import {
  useGetUserLanguageQuery,
  useGetUserThemeQuery,
} from 'src/redux/openapi';
import {
  resetUserData
} from 'src/redux/user';
import {
  useGetCurrentUserMutation
} from 'src/shared/api/users/usersApi';
import {
  LoaderPage
} from 'src/shared/components';
import {
  useNavigatorOnLine
} from 'src/shared/hooks/useNavigatorOnLine';
import {
  isErrorWithMessage
} from 'src/shared/utils';
import {
  ROUTE
} from 'src/shared/utils/routes';

const ProtectedRoute: FC = () => {
  const [getCurrentUser, {
    isSuccess
  }] = useGetCurrentUserMutation();

  useGetUserThemeQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  useGetUserLanguageQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const loadCurrentUser = async () => {
    try {
      await getCurrentUser().unwrap();
    } catch (error) {
      if (isErrorWithMessage(error)) {
        dispatch(resetUserData());

        navigate(
          `/${ROUTE.LOGIN}`,
          {
            replace: true,
          }
        );
      }
    }
  };

  useEffect(
    () => {
      loadCurrentUser();
    },
    []
  );

  const isOnLine = useNavigatorOnLine();

  if (!isOnLine) {
    return <LoaderPage />;
  }

  return isSuccess ? <Outlet /> : null;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTE.ROOT}
          element={<OnboardingQuestion />}
        />

        <Route
          path={ROUTE.GROUP_PROMOTION_STATUS}
          element={<GroupPromotionStatus />}
        />

        <Route
          path={ROUTE.COMMUNITY}
          element={<Community />}
        />

        <Route path={ROUTE.ORGANISATION}>
          <Route
            index
            element={<Organisation />}
          />

          <Route path={`:${ROUTE.ORGANIZATION_ID}`}>
            <Route
              index
              element={<Organisation />}
            />

            <Route
              path={ROUTE.SETUP_ORGANISATION}
              element={<SetupOrganization />}
            />

            <Route
              path={ROUTE.MANAGE_INVITATIONS}
              element={<ManageInvitations />}
            />

            <Route
              path={ROUTE.EDIT_QUESTIONS}
              element={<EditQuestions />}
            />

            <Route
              path={ROUTE.REMOVE_IN_BULK}
              element={<EditOrgQuestionsInBulk />}
            />

            <Route
              path={ROUTE.ADD_IN_BULK}
              element={<EditOrgQuestionsInBulk />}
            />

            <Route
              path={ROUTE.ADD_SET}
              element={<AddOgrQuestionSet />}
            />
          </Route>
        </Route>

        <Route path={ROUTE.ARQ_MANAGEMENT}>
          <Route
            index
            element={<OrganizationManagement />}
          />

          <Route
            path={ROUTE.ADD_SET}
            element={<AddAdminQuestionSet />}
          />
        </Route>

        <Route
          path={ROUTE.NOTIFICATIONS}
          element={<NotificationsPage />}
        />

        <Route path={ROUTE.QUESTION}>
          <Route
            index
            element={<OnboardingQuestion />}
          />

          <Route
            path=":id"
            element={<Question />}
          />
        </Route>

        <Route
          path={ROUTE.GROUPS}
          element={<GroupPage />}
        >
          <Route
            index
            element={<GroupsOverview />}
          />

          <Route
            path={ROUTE.CREATE_GROUP}
            element={<CreateGroup />}
          />

          <Route
            path={`:${ROUTE.GROUP_ID}`}
            element={<GroupsOverview />}
          />
        </Route>

        <Route path={`/${ROUTE.GROUPS}/:${ROUTE.GROUP_ID}`}>
          <Route
            path={ROUTE.EDIT_QUESTIONS}
            element={<EditQuestions />}
          />

          <Route
            path={ROUTE.SET_ONBOARDING_TEXT}
            element={<EditQuestionsInGroup />}
          />
        </Route>

        <Route path={ROUTE.CALIBRATION_TOOL}>
          <Route
            index
            element={<CalibrationTool />}
          />

          <Route
            path={`${ROUTE.COUNT_DOWN}/:${ROUTE.CALIBRATION_ID}`}
            element={<CountDown />}
          />

          <Route
            path={`${ROUTE.CALIBRATION_RESULTS}/:${ROUTE.CALIBRATION_ID}`}
            element={<CalibrationResults />}
          />

          <Route
            path={`:${ROUTE.CALIBRATION_ID}`}
            element={<CalibrationQuestion />}
          />
        </Route>

        <Route
          path={`${ROUTE.POST_ESTIMATION_FEEDBACK}/:${ROUTE.QUESTION_ID}`}
          element={<PostEstimationFeedback />}
        />

        <Route
          path={`/${ROUTE.PROFILE}`}
          element={<ProfilePage />}
        />
      </Route>

      <Route
        path={ROUTE.ABOUT_US}
        element={<ComingSoon />}
      />

      <Route
        path={ROUTE.HELP_CENTER}
        element={<ComingSoon />}
      />

      <Route
        path={ROUTE.PRIVACY_POLICY}
        element={<PrivacyPolicy />}
      />

      <Route
        path={ROUTE.TERMS_REGULATIONS}
        element={<TermOfServices />}
      />

      <Route
        path={ROUTE.FAQ}
        element={<FaqPage />}
      />

      <Route element={<Login />}>
        <Route
          path={ROUTE.LOGIN}
          element={<EnterPasswordPage />}
        />

        <Route
          path={ROUTE.SOCIAL_LOGIN}
          element={<EnterPasswordPage isSocials />}
        />

        <Route
          path={ROUTE.CREATE_ACCOUNT}
          element={<CreateAccountPage />}
        />

        <Route
          path={ROUTE.CREATE_PASSWORD}
          element={<CreatePasswordPage />}
        />

        <Route
          path={ROUTE.SOCIAL_CREATE_ACCOUNT}
          element={<CreateAccountPage isSocial />}
        />

        <Route
          path={ROUTE.CREATION_SUCCESS}
          element={<CreationSuccessPage />}
        />

        <Route
          path={ROUTE.RESET_PASSWORD}
          element={<ResetPasswordPage />}
        />

        <Route
          path={ROUTE.RECOVERY_CODE}
          element={<RecoveryCodePage />}
        />

        <Route
          path={ROUTE.ACCOUNT_BANNED}
          element={<AccountBannedPage />}
        />

        <Route
          path={ROUTE.EMAIL_VERIFICATION}
          element={<EmailVerification />}
        />
      </Route>

      <Route
        path={ROUTE.CREATE_GROUP}
        element={<GroupPage />}
      >
        <Route
          index
          element={<CreateGroup />}
        />
      </Route>

      <Route
        path="*"
        element={<NotFound />}
      />
    </Route>,
  ),
);

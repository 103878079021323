import dayjs from 'dayjs';
import React, {
  FC, FormEvent, useState
} from 'react';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  useTranslation
} from 'react-i18next';
import {
  zodResolver
} from '@hookform/resolvers/zod';

import {
  DatePickerMenu
} from 'src/features/DatePicker';
import {
  usePostOrgQuestionAnswersMutation,
  usePostQuestionAnswersMutation,
} from 'src/redux/openapi';
import {
  Button,
  Checkbox,
  Input,
  InputTextArea,
  ModalContainer,
  PopoverMenu,
  RadioInput,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  CalendarIcon
} from 'src/shared/icons';
import {
  formatDateRange,
  formatDateStringToDate,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  useGetInputValidation
} from 'src/shared/hooks';

import * as Style from './SetQuestionScoreModal.styles';

interface SetQuestionScoreModalProps {
  closeModal: () => void;
  isOpen: boolean;
  organizationId?: string;
  questionId: string | null;
  hidePicker?: boolean;
  startDate?: string;
  endDate?: string;
}

interface QuestionScoringForm {
  date: string;
  result: boolean;
  feedbackAvailable: boolean;
  message: string;
}

export const SetQuestionScoreModal: FC<SetQuestionScoreModalProps> = ({
  closeModal,
  isOpen,
  organizationId,
  questionId,
  hidePicker,
  startDate,
  endDate,
}) => {
  const [shouldCloseMenu, setShouldCloseMenu] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [postAdminAnswer] = usePostQuestionAnswersMutation();
  const [postOrgAnswer] = usePostOrgQuestionAnswersMutation();

  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const {
    scoreQuestionSchema
  } = useGetInputValidation();

  const {
    control,
    formState: {
      errors
    },
    handleSubmit,
    reset,
    clearErrors,
    watch,
  } = useForm<QuestionScoringForm>({
    mode: 'onTouched',
    defaultValues: {
      date: '',
      result: true,
      feedbackAvailable: false,
      message: '',
    },
    values: {
      date: hidePicker
        ? ''
        : formatDateRange({
          start: dayjs().toDate(),
          language,
        }),
      result: true,
      feedbackAvailable: false,
      message: '',
    },
    resolver: zodResolver(scoreQuestionSchema),
  });

  const onClose = () => {
    reset();
    clearErrors();
    closeModal();
  };

  const onSubmit = handleSubmit(async (data: QuestionScoringForm) => {
    if (!questionId) {
      return;
    }

    setIsSubmitting(true);

    const {
      date, result, feedbackAvailable, message
    } = data;

    const body = {
      result,
      endDate: date
        ? formatDateStringToDate(date).toISOString()
        : endDate || dayjs().toISOString(),
      postDebiasing: feedbackAvailable
        ? {
          message,
        }
        : null,
    };

    try {
      const response = organizationId
        ? await postOrgAnswer({
          questionId,
          id: organizationId,
          updateQuestionAnswerBodySchema: body,
        }).unwrap()
        : await postAdminAnswer({
          id: questionId,
          updateQuestionAnswerBodySchema: body,
        }).unwrap();

      successfulToast(response.message);

      onClose();
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsSubmitting(false);
    }
  });

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  const isFeedbackAvailable = watch('feedbackAvailable');

  const now = dayjs().toDate();
  const minDate = startDate ? dayjs(startDate).toDate() : null;

  return (
    <ModalContainer
      isDarkBackground
      closeModalHandler={onClose}
      isOpen={isOpen}
      modalWidth={634}
      modalTitle={t('question.setQuestionScore')}
    >
      <Style.FormContainer onSubmit={onFormSubmit}>
        {!hidePicker && (
          <Controller
            name="date"
            control={control}
            render={({
              field: {
                value, onChange, onBlur
              }
            }) => {
              const pickerValue = value
                ? formatDateStringToDate(value)
                : dayjs().toDate();

              const onDatePickerChange = (date: Date | null) => {
                if (!date) {
                  onChange('');

                  return;
                }

                const dateString = formatDateRange({
                  start: date,
                  language,
                });

                onChange(dateString);

                setShouldCloseMenu(true);
              };

              return (
                <PopoverMenu
                  zIndex={10000}
                  onClose={() => setShouldCloseMenu(false)}
                  shouldForcedClose={shouldCloseMenu}
                  placement="bottom-end"
                  renderCustomButton={({
                    triggerProps, toggleMenu
                  }) => (
                    <div {...triggerProps}>
                      <Input
                        value={value}
                        onBlur={onBlur}
                        placeholder={t(
                          'inputMessages.SELECT_QUESTION_SCORE_DATE',
                        )}
                        label={t('question.questionScoreDate')}
                        renderRightContent={() => (
                          <Style.CalendarIconContainer>
                            <CalendarIcon />
                          </Style.CalendarIconContainer>
                        )}
                        onClick={() => toggleMenu()}
                        isRequired
                        errorMessage={errors.date?.message}
                      />
                    </div>
                  )}
                >
                  <DatePickerMenu
                    isMultiple={false}
                    onApply={onDatePickerChange}
                    saveOnChange
                    clearButtonTitle={t('buttons.clear')}
                    selectedDateRange={pickerValue}
                    maxDate={now}
                    minDate={minDate}
                  />
                </PopoverMenu>
              );
            }}
          />
        )}

        <Controller
          name="result"
          control={control}
          render={({
            field: {
              value, onChange
            }
          }) => (
            <div>
              <Style.LabelText>
                {t('question.chooseQuestionResult')}
              </Style.LabelText>

              <RadioInput
                checked={value}
                label={t('buttons.yes')}
                labelClassName="mb-2"
                onChange={() => onChange(true)}
              />

              <RadioInput
                checked={!value}
                label={t('buttons.no')}
                onChange={() => onChange(false)}
              />
            </div>
          )}
        />

        <Controller
          name="feedbackAvailable"
          control={control}
          render={({
            field: {
              value, onChange
            }
          }) => (
            <Checkbox
              isChecked={value}
              onChange={onChange}
              type="checkMark"
              label={t('question.availableForPostEstimationFeedback')}
              className="gap-2 py-4 px-2 border border-input-blue"
            />
          )}
        />

        <Controller
          name="message"
          control={control}
          render={({
            field: {
              value, onChange, onBlur
            }
          }) => (
            <label>
              <Style.LabelText>{t('common.message')}</Style.LabelText>

              <InputTextArea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={
                  isFeedbackAvailable
                    ? `${t('inputMessages.ENTER_MESSAGE')}...`
                    : `${t('inputMessages.ENABLE_FEEDBACK_TO_ENTER_MESSAGE')}`
                }
                rows={7}
                className="w-full outline-0 disabled:bg-gray-whisper"
                disabled={!isFeedbackAvailable}
              />
            </label>
          )}
        />

        <Style.ButtonContainer>
          <Button
            variant="big-grey-bordered"
            className="w-max font-bold !py-3 !px-6 text-xl"
            onClick={onClose}
            disabled={isSubmitting}
          >
            {t('buttons.cancel')}
          </Button>

          <Button
            variant="big-blue"
            className="w-max font-bold !py-3 !px-8 text-xl"
            onClick={onSubmit}
            type="submit"
            disabled={isSubmitting}
          >
            {t('buttons.save')}

            {isSubmitting && (
              <Spinner
                size={24}
                color="white"
              />
            )}
          </Button>
        </Style.ButtonContainer>
      </Style.FormContainer>
    </ModalContainer>
  );
};
